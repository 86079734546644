import { Link as MUILink, LinkProps as MUILinkProps } from '@mui/material'
import { ForwardRefRenderFunction, forwardRef } from 'react'
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from 'react-router-dom'

type Props = MUILinkProps & RouterLinkProps

const Link: ForwardRefRenderFunction<any, Props> = (
  { to, href, ...props },
  ref
) => {
  const finalTo = to || href || '/'
  return <MUILink ref={ref} component={RouterLink} to={finalTo} {...props} />
}

export default forwardRef(Link)
