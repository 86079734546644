import { request, ResponseBody } from '../request'
import { DocumentType, DocumentTypeMetadataField, DocumentTypeRaw } from '../types'

const main_path = 'document-types'

export type GetResponseDocumentTypes = ResponseBody<DocumentTypeRaw[]>

export type GetRequestDocumentTypes = {
  ids?: string | string[]
}

export const processDocumentType = (dt: DocumentTypeRaw): DocumentType | null => {
  try {
    return {
      ...dt,
      metadata_fields: JSON.parse(dt.metadata_fields),
    }
  } catch (e) {
    console.error('Failed to extract metadata', e)
  }

  return null
}

export const getDocumentsTypes = async (ids?: GetRequestDocumentTypes['ids']) => {
  const results = await request<GetRequestDocumentTypes, GetResponseDocumentTypes>({
    path: main_path,
    method: 'get',
    query: {
      ids: typeof ids === 'string' ? [ids] : ids,
    },
  })

  if (!results?.payload) {
    return []
  }

  const documentsTypes: DocumentType[] = []

  const records = results?.payload
  if (records?.length) {
    for (let i = 0; i < records.length; i += 1) {
      const dt = processDocumentType(records[i])
      if(dt) {
        documentsTypes.push(dt)
      }
    }
  }

  return documentsTypes || []
}

// CREATE =====================================

type CreateParamsDocumentTypes = {
  name: string
  industry_id?: string
  metadata_fields: DocumentTypeMetadataField[]
  storage_path?: string
}

type CreateResponseDocumentTypes = ResponseBody & {
  document_type: DocumentType
}

export const createDocumentType = async ({ ...data }: CreateParamsDocumentTypes) => {
  const results = await request<CreateParamsDocumentTypes, CreateResponseDocumentTypes>({
    path: `${main_path}`,
    method: 'post',
    data,
  })

  return results
}

// UPDATE =====================================

type UpdateParamsDocumentTypes = {
  id: string
  name?: string
  industry_id?: string
  metadata_fields?: DocumentTypeMetadataField[]
  status?: DocumentType['status']
  storage_path?: string
}

type UpdateResponseDocumentTypes = ResponseBody & {
  document_type: DocumentType
}

export const updateDocumentType = async ({ ...data }: UpdateParamsDocumentTypes) => {
  const results = await request<UpdateParamsDocumentTypes, UpdateResponseDocumentTypes>({
    path: `${main_path}/${data.id}`,
    method: 'patch',
    data,
  })

  return results
}

// DISABLE =====================================

type DisableParamsDocumentTypes = {
  id: string
  status: DocumentType['status']
}

type DisableResponseDocumentTypes = ResponseBody & {
  document_type: DocumentType
}

export const disableDocumentType = async ({ ...data }: DisableParamsDocumentTypes) => {
  const results = await request<DisableParamsDocumentTypes, DisableResponseDocumentTypes>({
    path: `${main_path}/disable`,
    method: 'post',
    data,
  })

  return results
}
