import {
  Avatar,
  Box,
  CircularProgress,
  Divider,
  IconButton,
  MenuItem,
  Typography,
} from '@mui/material'
import { alpha } from '@mui/material/styles'
import { FC, useRef, useState } from 'react'
import { NavLink } from 'react-router-dom'
import MenuPopover from '../../components/MenuPopover'
import { Account, logout } from '../../data'
import { useSession } from '../../lib/api/auth/use-session'

type AccountPopoverProps = {
  account: Account
}

const AccountPopover: FC<AccountPopoverProps> = ({ account }) => {
  const anchorRef = useRef(null)
  const [open, setOpen] = useState(null)
  const [isLoggingOut, setIsLoggingOut] = useState(false)
  const { mutate } = useSession()

  const handleOpen = (event: any) => {
    setOpen(event.currentTarget)
  }

  const handleClose = () => {
    setOpen(null)
  }

  const handleLogout = async () => {
    setIsLoggingOut(true)
    await logout()
    await mutate()
    setIsLoggingOut(false)
  }

  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open ? {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          } : {}),
        }}
      >
        <Avatar src={account?.photoURL} alt="photoURL" />
      </IconButton>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {account?.displayName}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {account?.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />
        <NavLink style={{ textDecoration: 'none' }} to={'/dashboard/profile'}>
          <MenuItem sx={{ m: 1, color: '#000' }}>My Profile</MenuItem>
        </NavLink>
        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
          {isLoggingOut ? <CircularProgress size={16} /> : 'Logout'}
        </MenuItem>
      </MenuPopover>
    </>
  )
}

export default AccountPopover
