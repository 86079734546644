import Iconify from '../../components/Iconify'
import { User } from '../../data'

const getIcon = (name: string) => <Iconify icon={name} width={22} height={22} />

const documents = {
  title: 'AID Search',
  path: '/dashboard/documents',
  icon: getIcon('ion:documents'),
}

const document_types = {
  title: 'Document Types',
  path: '/dashboard/document-types',
  icon: getIcon('gridicons:custom-post-type'),
}

const customers = {
  title: 'Customers',
  path: '/dashboard/customers',
  icon: getIcon('mdi:domain'),
}

const members = {
  title: 'Members',
  path: '/dashboard/members',
  icon: getIcon('gridicons:multiple-users'),
}

const groups = {
  title: 'Companies',
  path: '/dashboard/groups',
  icon: getIcon('bi:boxes'),
}

const document_groups = {
  title: 'Document Groups',
  path: '/dashboard/document-groups',
  icon: getIcon('formkit:group'),
}

const dashboard = {
  title: 'Dashboard (Beta)',
  path: '/dashboard',
  icon: getIcon('wpf:statistics'),
}

const logs = {
  title: 'Audit Log',
  path: '/dashboard/logs',
  icon: getIcon('carbon:document'),
}

const navConfig = (role?: User['role']) => {
  return role === 'super'
    ? [document_types, customers, members, ]
    : role === 'admin'
    ? [dashboard, documents, members, groups, document_groups, logs]
    : [documents, groups, document_groups]
}

export default navConfig
