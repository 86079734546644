import { Breakpoint, useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

export default function useResponsive(
  query?: 'up' | 'down' | 'between' | 'only',
  providedKey?: number | Breakpoint,
  providedStart?: number | Breakpoint,
  providedEnd?: number | Breakpoint
) {
  const theme = useTheme()
  const key = providedKey || 'lg'
  const start = providedStart || 'sm'
  const end = providedEnd || 'lg'
  const mediaUp = useMediaQuery(theme.breakpoints.up(key))
  const mediaDown = useMediaQuery(theme.breakpoints.down(key))
  const mediaBetween = useMediaQuery(theme.breakpoints.between(start, end))
  const mediaOnly = useMediaQuery(theme.breakpoints.only(typeof key === 'number' ? 'lg' : key))

  if (query === 'up') {
    return mediaUp
  }

  if (query === 'down') {
    return mediaDown
  }

  if (query === 'between') {
    return mediaBetween
  }

  if (query === 'only' && typeof key !== 'number') {
    return mediaOnly
  }
  return null
}
