import Cookies from 'js-cookie'
import isEqual from 'lodash/isEqual.js'
import { createContext, useEffect, useMemo, useRef, useState } from 'react'
import useSWR, { KeyedMutator } from 'swr'
import { User } from '../../../data'
import { ErrorApiResponse } from '../types'

interface SessionResponse {
  authenticated: boolean
  user?: User
  token?: string
}
interface SessionData {
  user: User
  token: string
}

interface AuthContextInterface {
  user: User | null
  token: string | null
  status: 'loading' | 'unauthenticated' | 'authenticated'
  error: ErrorApiResponse | null
  mutate: KeyedMutator<SessionResponse>
}

const getJsonFromCookie = <O extends any>(key: string): O | null => {
  const cookie = Cookies.get(key)
  if (cookie) {
    try {
      return JSON.parse(cookie)
    } catch (e) {
      return null
    }
  }
  return null
}

const loadInitialState = (): AuthContextInterface => {
  const user = getJsonFromCookie<User>('user')
  const token = Cookies.get('token') || null

  if (user && token) {
    return {
      user,
      token,
      status: 'authenticated',
      error: null,
      mutate: async () => undefined
    }
  } else {
    return {
      user: null,
      token: null,
      status: 'loading',
      error: null,
      mutate: async () => undefined
    }
  }
}

export const AuthContext = createContext<AuthContextInterface>(loadInitialState())

const getFallbackData = (): SessionResponse | undefined => {
  const initialState = loadInitialState()
  if (
    initialState.status === 'authenticated' &&
    initialState.user &&
    initialState.token
  ) {
    return {
      authenticated: !!initialState.user,
      user: initialState.user,
      token: initialState.token
    }
  }
}

const extractSessionData = (res: SessionResponse | undefined): SessionData | null => {
  if(res?.authenticated) {
    return {
      user: res.user!,
      token: res.token!,
    }
  }
  return null
}
const useMemoizedSessionSWR = () => {
  const {
    data: swrData,
    error,
    isLoading,
    mutate,
  } = useSWR<SessionResponse, ErrorApiResponse>('/api/session', {
    fallbackData: getFallbackData(),
  })

  const [data, setData] = useState<SessionData | null>(extractSessionData(swrData))

  useEffect(() => {
    if(error) {
      setData(null)
      return
    }

    const newData = extractSessionData(swrData)
    if (!isEqual(data, newData)) {
      setData(newData)
    }
  }, [data, swrData, error])

  return { data, error, isLoading, mutate }
}

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const { data, error, isLoading, mutate } = useMemoizedSessionSWR()
  const isServerError = !(error instanceof Error)

  const dataRef = useRef(data)

  useEffect(() => {
    if (!isEqual(dataRef.current, data)) {
      if(data?.token !== dataRef.current?.token) {
        if(data?.token) {
          Cookies.set('token', data?.token)
        } else {
          Cookies.remove('token')
        }
      }

      if(data?.user !== dataRef.current?.user) {
        if(data?.user) {
          Cookies.set('user', JSON.stringify(data?.user))
        } else {
          Cookies.remove('user')
        }
      }
    }

    dataRef.current = data
  }, [data])

  const contextData: AuthContextInterface = useMemo(() => {
    return {
      user: data?.user || null,
      token: data?.token || null,
      status: isLoading ? 'loading' : (isServerError ? 'unauthenticated' : 'authenticated'),
      error: isServerError ? error || null : {
        success: false,
        details: 'Unknown Error',
        errors: {}
      },
      mutate,
    }
  }, [data, error, isServerError, isLoading, mutate])

  return <AuthContext.Provider value={contextData}>{children}</AuthContext.Provider>
}
