import { LocalizationProvider as DatePickerLocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import en from 'date-fns/locale/en-US'
import { SWRConfig } from 'swr'
import ScrollToTop from './components/ScrollToTop'
import { fetcher } from './lib/api'
import { AuthProvider } from './lib/api/auth/context'
import Router from './routes'
import ThemeProvider from './theme'
import { Toaster } from 'react-hot-toast'
import { processDocument } from './lib/swr-middleware/process-document'

export default function App() {
  return (
    <>
      <SWRConfig
        value={{
          fetcher,
          use: [
            processDocument
          ]
        }}
      >
        <AuthProvider>
          <ThemeProvider>
            <ScrollToTop />
            <DatePickerLocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={en}>
              <Router />
            </DatePickerLocalizationProvider>
          </ThemeProvider>
        </AuthProvider>
      </SWRConfig>
      <Toaster position="bottom-center" />
    </>
  )
}
