import { request, ResponseBody } from '../request'
import { getUser } from '../stored'
import { Customer } from '../types'

// GET =====================================

export type IncludeOptions = '_count' | 'users' | 'root_user' | 'root_token'

type GetCustomerOptions = {
  include?: IncludeOptions | IncludeOptions[]
}

export type GetParamsCustomers = {
  customer_ids?: string | string[]
  status?: Customer['status']
  search_query?: string
  include?: IncludeOptions | IncludeOptions[]
}

type GetResponseCustomers = ResponseBody & {
  customers: Customer[]
}

export const getCustomers = async ({
  customer_ids,
  status,
  search_query,
  include,
}: GetParamsCustomers) => {
  const results = await request<GetParamsCustomers, GetResponseCustomers>({
    path: 'customers',
    method: 'get',
    query: {
      customer_ids,
      status,
      search_query,
      include,
    },
  })

  return results?.payload || []
}

export const getCustomer = async (customer_id?: string, options?: GetCustomerOptions) => {
  if (!customer_id) {
    const user = getUser()
    customer_id = user?.customer_id
  }
  if(!customer_id) {
    return
  }

  const include = ['_count', 'users'] as IncludeOptions[]
  if(options?.include) {
    const newInclude = Array.isArray(options.include) ? options.include : [options.include]
    include.push(...newInclude)
  }
  const customers = await getCustomers({
    customer_ids: [customer_id],
    include,
  })

  if (customers?.length <= 0) {
    return
  }

  return customers[0]
}

// CREATE =====================================

type CreateParamsCustomers = Omit<Customer, 'id' | 'created_at' | 'status'> & {
  rootUser: {
    email: string
    firstName?: string
    lastName?: string
  }
}

type CreateResponseCustomers = ResponseBody & {
  customer: Customer
}

export const createCustomer = async ({ ...data }: CreateParamsCustomers) => {
  const results = await request<CreateParamsCustomers, CreateResponseCustomers>(
    {
      path: 'customers/create',
      method: 'post',
      data,
    }
  )

  return results
}

// UPDATE =====================================

type UpdateParams = Omit<Customer, 'created_at'>

type UpdateResponse = ResponseBody & {
  customer: Customer
}

export const updateCustomer = async ({ ...data }: UpdateParams) => {
  const results = await request<UpdateParams, UpdateResponse>({
    path: 'customers/update',
    method: 'post',
    data,
  })

  return results
}

// CHANGE STATUS =====================================

export const updateCustomerStatus = async (
  customer_id: string,
  status: Customer['status']
) => {
  return await updateCustomer({ id: customer_id, status })
}
