import axios from 'axios'
import { getToken, getUser, makeQueryFromDataObject, refreshToken } from '../data'
axios.defaults.withCredentials = true

const apiUrl = process.env.REACT_APP_API_URL
export const baseUrl = `${apiUrl}/api/`

export type ValidationError = { [error: string]: string }

export type RequestBody<T> = {
  path: string
  data?: T & {
    customer_id?: string
    user_id?: string
  }
  query?: T & {
    customer_id?: string
    user_id?: string
  }
  body?: any
  method?: 'post' | 'get' | 'patch' | 'delete' | 'put'
  headers?: { [key: string]: string }
}

export type ResponseBody<P extends any = any> = {
  success?: boolean
  details?: string
  errors?: ValidationError
  payload?: P
  [key: string]: any
}

export const request = async <T = unknown, K extends ResponseBody = {}>({
  path,
  body,
  query,
  data,
  method = 'post',
  headers = {},
}: RequestBody<T>): Promise<K> => {
  refreshToken()

  const queryString = query && makeQueryFromDataObject(query)
  const url = `${baseUrl}${path}${queryString ? `${path.includes('?') ? '&' : '?'}${queryString}` : ''}`
  const token = getToken()
  const user = getUser()
  const formatted = {
    ...data,
    user_id: data?.user_id || user?.id,
    customer_id: data?.customer_id || user?.customer_id,
    body,
    token,
  }

  const finalHeaders = {
    ...headers,
    ...(token && { Authorization: `Bearer ${token}` })
  }

  const promise = new Promise<K>((resolve) => {
    axios
      .request<K>({
        method,
        withCredentials: true,
        url,
        data: formatted,
        headers: finalHeaders,
      })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        resolve(error.response?.data)
      })
  })

  const result = await promise

  return result
}
