
type QueryValue = string | string[] | number | number[] | boolean | boolean[] | Date | Date[] | undefined | null

export const getValue = (value: any) => {
  if (typeof value !== 'undefined' && value !== null && value !== '') {
    return value
  }
  return null
}

export const toArray = (value: string | string[]) => {
  return typeof value === 'string' ? [value] : value
}

export const makeQueryFromDataObject = (object: Record<string, QueryValue>) => {
  const query = new URLSearchParams()

  for (const key in object) {
    const value = object[key]
    switch(typeof value) {
      case 'string':
      case 'number':
      case 'boolean':
        query.append(key, value.toString())
        break
      case 'object':
        if (Array.isArray(value)) {
          value.forEach((item) => {
            if(typeof item !== 'undefined' && item !== null && item !== '') {
              query.append(key, item.toString())
            }
          })
        }
        if(value instanceof Date) {
          query.append(key, value.toISOString())
        }
        break
      case 'undefined':
        break;
      default:
        throw new Error(`Invalid type ${typeof value} for query parameter ${key}`)
    }
  }

  return query
}