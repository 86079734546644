import { Icon } from '@iconify/react'
import { Box, BoxProps, SxProps } from '@mui/material'
import * as React from 'react'

interface IconifyProps extends BoxProps {
  icon: React.ElementType | string
  sx?: SxProps
}

const Iconify: React.FC<IconifyProps> = ({ icon, sx, ...rest }) => (
  <Box component={Icon} icon={icon} sx={{ ...sx }} {...rest} />
)

export default Iconify
