import merge from 'lodash/merge'
import useSWR from 'swr'
import useSWRInfinite from 'swr/infinite'
import { enhancedFetch } from './enhanced-fetch'

type FecherOptions = [string, RequestInit]

const baseUrl = process.env.REACT_APP_API_URL

if (!baseUrl) {
  throw new Error('REACT_APP_API_URL is not defined')
}

const defaultInit: RequestInit = {
  method: 'GET',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json; charset=utf-8',
  },
} as const

export const fetcher = async (rawOptions: FecherOptions | string) => {
  const options = typeof rawOptions === 'string' ? [rawOptions] : rawOptions
  const put = options[0]
  const url = put.startsWith('http') ? put : `${baseUrl}${put}`
  const init = options?.[1] ? options[1] : {}

  const response = await enhancedFetch(url, merge({}, defaultInit, init))
  if (response.success) {
    return response.payload
  }

  const error = new Error(response.payload?.details ?? 'Unknown error')
  // @ts-expect-error
  error.payload = response.payload
  throw error
}

export const useApi = useSWR
export const useApiInfinite = useSWRInfinite

export * from './types'
