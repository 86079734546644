import { useCallback, useMemo, useState } from 'react'
import { Outlet } from 'react-router-dom'
// material
import { styled } from '@mui/material/styles'
//
import { useSession } from '../../lib/api/auth/use-session'
import DashboardNavbar from './DashboardNavbar'
import DashboardSidebar from './DashboardSidebar'

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64
const APP_BAR_DESKTOP = 92

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
})

const MainStyle = styled('div')(({ theme }) => ({
  backgroundColor: '#EEFAFF',
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}))

// ----------------------------------------------------------------------

const DashboardLayout = () => {
  const [isOpen, setIsOpen] = useState(false)
  const { user } = useSession()
  const account = useMemo(() => {
    return user && {
      displayName: `${user.first_name} ${user.last_name}`,
      email: user.email,
      photoURL: '/static/illustrations/avatar_default_male.png',
      role: user.role,
    }
  }, [user])

  const open = useCallback(() => setIsOpen(true), [])
  const close = useCallback(() => setIsOpen(false), [])

  return (
    <RootStyle>
      <DashboardNavbar onOpenSidebar={open} account={account} />
      <DashboardSidebar
        isOpenSidebar={isOpen}
        onCloseSidebar={close}
        account={account}
      />
      <MainStyle>
        <Outlet />
      </MainStyle>
    </RootStyle>
  )
}

export default DashboardLayout
