import { Avatar, Box, Drawer, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { sentenceCase } from 'change-case'
import { FC } from 'react'
import Link from '../../components/Link'
import NavSection from '../../components/NavSection'
import Scrollbar from '../../components/Scrollbar'
import { Account } from '../../data'
import useResponsive from '../../hooks/useResponsive'
import palette from '../../theme/palette'
import navConfig from './NavConfig'

type DashboardSidebarProps = {
  isOpenSidebar?: boolean
  onCloseSidebar?: (
    event?: {},
    reason?: 'backdropClick' | 'escapeKeyDown'
  ) => void
  account?: Account | null
}

const DRAWER_WIDTH = 280

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
  },
}))

const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: palette.grey['50012'],
}))

const DashboardSidebar: FC<DashboardSidebarProps> = ({
  isOpenSidebar,
  onCloseSidebar,
  account,
}) => {

  const isDesktop = useResponsive('up', 'lg')

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': {
          height: 1,
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      <Box sx={{ px: 8, py: 4, display: 'inline-flex' }}>
        <img
          width={130}
          height={42}
          src={'/static/illustrations/logo.png'}
          alt="logo"
        />
      </Box>

      <Box sx={{ mb: 5, mx: 2.5 }}>
        <Link underline="none" to="#">
          <AccountStyle>
            <Avatar src={account?.photoURL} alt="photoURL" />
            <Box sx={{ ml: 2 }}>
              <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                {account?.displayName}
              </Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {account?.role && sentenceCase(account?.role)}
              </Typography>
            </Box>
          </AccountStyle>
        </Link>
      </Box>

      <NavSection onRouteChange={onCloseSidebar} navConfig={navConfig(account?.role)} />

      <Box sx={{ flexGrow: 1 }} />
    </Scrollbar>
  )

  return (
    <RootStyle>
      {!isDesktop && (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: 'background.default',
              borderRightStyle: 'dashed',
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  )
}

export default DashboardSidebar
