import { AppBar, Box, IconButton, Stack, Toolbar } from '@mui/material'
import { alpha, styled } from '@mui/material/styles'
import { FC, MouseEventHandler } from 'react'
import Iconify from '../../components/Iconify'
import { Account } from '../../data'
import AccountPopover from './AccountPopover'

const DRAWER_WIDTH = 280
const APPBAR_MOBILE = 64
const APPBAR_DESKTOP = 92

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  // backdropFilter: 'blur(6px)',
  // WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.0),
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`,
  },
}))

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}))

type DashboardNavbarProps = {
  account?: Account | null
  onOpenSidebar?: MouseEventHandler<HTMLButtonElement>
}

const DashboardNavbar: FC<DashboardNavbarProps> = ({
  account,
  onOpenSidebar,
}) => {
  return (
    <RootStyle>
      <ToolbarStyle>
        <IconButton
          onClick={onOpenSidebar}
          sx={{ mr: 1, color: 'text.primary', display: { lg: 'none' } }}
        >
          <Iconify icon="eva:menu-2-fill" sx={{}} />
        </IconButton>

        <Box sx={{ flexGrow: 1 }} />

        <Stack
          direction="row"
          alignItems="center"
          spacing={{ xs: 0.5, sm: 1.5 }}
        >
          {account && <AccountPopover account={account} />}
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  )
}

export default DashboardNavbar
