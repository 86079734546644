import Cookies from 'js-cookie'
import { User } from './types'


export const getToken = (): string | undefined => {
  return Cookies.get('token')
}

export const getUser = (): User | undefined => {
  const userRaw = Cookies.get('user')
  if(!userRaw) {
    return undefined
  }

  try {
    const user = JSON.parse(userRaw) as User
    return user
  } catch (error) {
    return undefined
  }
}
