import { type Middleware } from 'swr'
import { Document, DocumentMetadata } from '../../data/types'

export const processDocument: Middleware = (useSWRNext) => {
  return (key, fetcher, config) => {
    // Before hook runs...

    // Handle the next middleware, or the `useSWR` hook if this is the last one.
    const swr = useSWRNext<any>(key, fetcher, config)

    if (typeof swr.data === 'object' && swr.data) {
      if ('payload' in swr.data && 'document' in swr.data.payload) {
        return {
          ...swr,
          data: {
            ...swr.data,
            payload: {
              ...swr.data.payload,
              document: processSingleDocument(swr.data.payload.document),
            },
          },
        }
      }
    }

    return swr
  }
}

const processSingleDocument = (document: Document) => {
  let processed = { ...document }
  let fields = processed?.document_type?.metadata_fields
  if (typeof fields === 'string') {
    fields = JSON.parse(fields)
    processed.metadata_fields = fields
  }

  processed.display_data = []
  processed.metadata = {}
  for (const field in fields) {
    const metadata = JSON.parse(document?.[field]) as DocumentMetadata
    if (fields[field]?.display && metadata?.value) {
      processed.display_data.push(metadata.value)
    }

    if (fields[field].field_name === 'DocumentDate') {
      processed.document_created_at = metadata.value
    }
    processed[field] = metadata
    processed.metadata[fields[field].field_name] = metadata
  }

  const urlSegments = processed.url?.split('/') || []
  processed.file_name = urlSegments[urlSegments.length - 1]

  return processed
}
