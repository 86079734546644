import { getToken } from '../../data'
import { ErrorApiResponse } from './types'

type EnhancedFetchResponse<T, E> =
  | {
      success: true
      payload: T
    }
  | {
      success: false
      payload: E | null
    }


export async function enhancedFetch<T, E = ErrorApiResponse>(
  rawURL: string,
  options?: RequestInit
): Promise<EnhancedFetchResponse<T, E>> {
  try {
    const url = rawURL.startsWith('http') ? rawURL : `${process.env.REACT_APP_API_URL}${rawURL}`
    const authToken = getToken()

    const response = await fetch(url, {
      ...options,
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json; charset=utf-8',
        ...(authToken ? { Authorization: `Bearer ${authToken}` } : {}),
        ...options?.headers,
      }
    })

    const text = await response.text()
    const json = JSON.parse(text)

    if (response.ok) {
      return { success: true, payload: json }
    } else {
      return { success: false, payload: json }
    }
  } catch (error) {
    console.error('Error parsing json', error)
    return { success: false, payload: null }
  }
}
