import axios from 'axios'
import { baseUrl, request, ResponseBody } from '../request'
import { getToken, getUser } from '../stored'
import { Document, DocumentMetadata, GroupBreadcrumbs } from '../types'
import { toArray } from '../utils'

axios.defaults.withCredentials = true

// GET =====================================

type GetResponseOneDocument = ResponseBody & {
  document: Document
  breadcrumbs: GroupBreadcrumbs
}

type GetResponseDocuments = ResponseBody & {
  payload: {
    documents: Document[]
    total_sum?: number
    status_sum?: {
      pending?: number
      verified?: number
    }
    skip?: number
  }
}

export type GetParamsDocuments = {
  document_ids?: string | string[]
  document_type_ids?: string | string[]
  search_query?: string
  start_date?: string | Date
  end_date?: string | Date
  status?: Document['status']
  archived?: Document['archived']
  skip?: number
  take?: number
  group_id?: string
  customer_id?: string
  parent_group_id?: string
}

export const getDocuments = async ({
  document_ids,
  document_type_ids,
  ...rest
}: GetParamsDocuments) => {
  const types =
    document_type_ids && document_type_ids !== ''
      ? toArray(document_type_ids)
      : undefined

  const start_date = rest.start_date && new Date(rest.start_date)
  const end_date = rest.end_date && new Date(rest.end_date)

  const data: GetParamsDocuments = {
    document_ids: (document_ids && document_ids.length) ? toArray(document_ids) : undefined,
    document_type_ids: types,
    ...rest,
    start_date,
    end_date,
  }

  const query = new URLSearchParams()

  for (const key in data) {
    const value = data[key as keyof GetParamsDocuments]
    if (value) {
      const finalValue = value instanceof Date ? value.toISOString() : value.toString()
      query.append(key, finalValue)
    }
  }

  const results = await request<GetParamsDocuments, GetResponseDocuments>({
    path: `documents?${query}`,
    method: 'get',
    // data,
  })

  let documents = []
  if (results?.payload?.documents) {
    for (const document of results.payload.documents) {
      documents.push(processDocument(document))
    }
  }

  return { 
    ...results, 
    payload: {
      ...results.payload,
      documents,
    } 
  }
}

export const getOneDocument = async (document_id: string) => {
  const result = await request<{ document_id: string }, GetResponseOneDocument>(
    {
      path: `documents/${document_id}`,
      method: 'get',
    }
  )

  const document = processDocument(result.document)

  return {
    document,
    breadcrumbs: result.breadcrumbs,
  }
}

const processDocument = (document: Document) => {
  let processed = { ...document }
  let fields = processed?.document_type?.metadata_fields
  if (typeof fields === 'string') {
    fields = JSON.parse(fields)
    processed.metadata_fields = fields
  }

  processed.display_data = []
  processed.metadata = {}
  for (const field in fields) {
    const metadata = JSON.parse(document?.[field]) as DocumentMetadata
    if (fields[field]?.display && metadata?.value) {
      processed.display_data.push(metadata.value)
    }

    if (fields[field].field_name === 'DocumentDate') {
      processed.document_created_at = metadata.value
    }
    processed[field] = metadata
    processed.metadata[fields[field].field_name] = metadata
  }

  const urlSegments = processed.url?.split('/') || []
  processed.file_name = urlSegments[urlSegments.length - 1]

  return processed
}

// CREATE =====================================

type CreateParams = {
  group_id?: string
  document_type_id?: string
  metadata?: { [key: string]: string | number }
  token?: string
  url: string
}

type CreateResponse = ResponseBody & {
  document: Document
}

export const createDocument = async ({
  document_type_id,
  metadata,
  group_id,
  url,
  token,
}: CreateParams) => {
  const user = getUser()
  if(!user) {
    return
  }

  const results = await request<CreateParams, CreateResponse>({
    path: 'documents/create',
    method: 'post',
    data: {
      document_type_id,
      customer_id: user.customer_id,
      group_id,
      url,
      token,
      ...metadata,
    },
  })

  return results.document
}

// UPDATE =====================================

type UpdateParams = {
  document_id: string
  customer_id?: string
  group_id?: string
  metadata?: { [key: string]: DocumentMetadata }
}

type UpdateResponse = ResponseBody & {
  document: Document
}

export const updateDocument = async ({
  document_id,
  metadata,
  group_id,
}: UpdateParams) => {
  const user = getUser()
  if(!user) {
    return
  }

  const results = await request<UpdateParams, UpdateResponse>({
    path: 'documents/update',
    method: 'post',
    data: {
      document_id,
      customer_id: user.customer_id,
      group_id,
      ...metadata,
    },
  })

  return results
}

// ARCHIVE =====================================

export type ArchiveSelect = {
  selectedAll?: boolean
  exceptions?: string[]
  counter?: number
}

type ArchiveParams = GetParamsDocuments & ArchiveSelect

type ArchiveResponse = ResponseBody

export const archiveDocuments = async ({ ...data }: ArchiveParams) => {
  const results = await request<ArchiveParams, ArchiveResponse>({
    path: 'documents/archive',
    method: 'post',
    data: {
      ...data,
    },
  })

  return results
}

// UPLOAD =====================================

type DocumentUploadParams = {
  file: File
  folder: string
  group_id: string
  document_type_id?: string
  metadata?: CreateParams['metadata']
  path?: string
  onUploadProgress?: (percentage: number) => void
}

export const uploadDocument = async ({
  file,
  folder,
  group_id,
  document_type_id,
  metadata,
  path,
  onUploadProgress,
}: DocumentUploadParams) => {
  const token = getToken()
  const user = getUser()

  const { name, serial, ext } = getFileData(file.name)

  const newFilename = `${name}-${serial}${ext ? `.${ext}` : ``}`

  let formData = new FormData()
  formData.append('file', file, newFilename)

  const results = await axios(baseUrl + `documents/upload`, {
    method: 'POST',
    withCredentials: true,
    data: formData,
    headers: {
      folder,
      user_id: user?.id,
      customer_id: user?.customer_id,
      group_id,
      token,
    },
    onUploadProgress: (progressEvent) => {
      if (onUploadProgress) {
        const total =
          typeof progressEvent.total === 'number' && progressEvent.total > 0
            ? progressEvent.total
            : 1
        const current = progressEvent.loaded
        let percent = Math.floor((current / total) * 100)
        onUploadProgress(percent)
      }
    },
  })
    .then((res) => res.data)
    .then((json) => {
      return json
    })

  if (document_type_id === 'Others') {
    await createDocument({
      document_type_id,
      metadata,
      group_id,
      url: `https://infra365sb.sharepoint.com/sites${path}/${newFilename}`,
    })
  }

  return results
}

const getFileData = (filename: string) => {
  const ext = filename.split('.').pop()
  const name = filename.replace(/\.[^/.]+$/, '')
  const serial = Math.floor(Math.random() * 999999999 + 1)
  return {
    ext: ext === name ? undefined : ext,
    name,
    serial,
  }
}
